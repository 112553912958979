@font-face {
    font-family: 'Montserrat';
    src: url('assets/fonts/JTUSjIg1_i6t8kCHKm459Wlhyw.woff2') format("woff2");
}

@font-face {
    font-family: 'Montserrat';
    src: url('assets/fonts/JTUSjIg1_i6t8kCHKm459Wdhyzbi.woff2') format("woff2");
}

@font-face {
    font-family: 'Montserrat';
    src: url('assets/fonts/JTUSjIg1_i6t8kCHKm459W1hyzbi.woff2') format("woff2");
}

@font-face {
    font-family: 'Montserrat-Regular';
    src: url('assets/fonts/Montserrat-Regular.ttf') format('truetype');
}

@font-face {
    font-family: 'Montserrat-Bold';
    src: url('assets/fonts/Montserrat-Bold.ttf') format('truetype');
}

@font-face {
    font-family: opel-sans;
    src: url('assets/fonts/OpelSansCondensed.ttf') format('truetype');
}

@font-face {
    font-family: opel-bold;
    src: url('assets/fonts/OpelSansCondensed-Bd.ttf') format('truetype');
}

@font-face {
    font-family: opel-extra-bold;
    src: url('assets/fonts/OpelSansCondensed-ExBd.ttf') format('truetype');
}

@font-face {
    font-family: NotoSansArabic-Regular;
    src: url('assets/fonts/NotoSansArabic-Regular.ttf') format('truetype');
}

@font-face {
    font-family: NotoSansArabic-Bold;
    src: url('assets/fonts/NotoSansArabic-Bold.ttf') format('truetype');
}